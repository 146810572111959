export default function IconDiscord() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="20"
      viewBox="0 0 27 20"
      fill="none"
    >
      <path
        d="M22.608 1.67497C20.8896 0.881212 19.0756 0.318094 17.2122 0C16.9572 0.459917 16.7265 0.933111 16.521 1.4176C14.5362 1.11582 12.5177 1.11582 10.5328 1.4176C10.3273 0.933161 10.0965 0.459973 9.84166 0C7.97709 0.32078 6.16187 0.885235 4.4418 1.67912C1.02703 6.77667 0.101343 11.7476 0.564188 16.648C2.56396 18.1388 4.80228 19.2725 7.18185 20C7.71765 19.2729 8.19178 18.5015 8.59918 17.6941C7.82537 17.4025 7.07851 17.0427 6.36724 16.6189C6.55443 16.4819 6.73752 16.3408 6.91442 16.2038C8.98406 17.1859 11.243 17.695 13.53 17.695C15.8171 17.695 18.076 17.1859 20.1456 16.2038C20.3246 16.3512 20.5077 16.4923 20.6928 16.6189C19.9802 17.0434 19.2319 17.4038 18.4568 17.6961C18.8637 18.5032 19.3378 19.2739 19.8741 20C22.2557 19.2755 24.4957 18.1422 26.4959 16.6501C27.0389 10.9672 25.5681 6.04193 22.608 1.67497ZM9.18545 13.6343C7.89566 13.6343 6.83008 12.4533 6.83008 11.0004C6.83008 9.54753 7.85863 8.35616 9.18134 8.35616C10.504 8.35616 11.5614 9.54753 11.5388 11.0004C11.5161 12.4533 10.4999 13.6343 9.18545 13.6343ZM17.8746 13.6343C16.5827 13.6343 15.5213 12.4533 15.5213 11.0004C15.5213 9.54753 16.5498 8.35616 17.8746 8.35616C19.1994 8.35616 20.2485 9.54753 20.2259 11.0004C20.2032 12.4533 19.1891 13.6343 17.8746 13.6343Z"
        fill="white"
      />
    </svg>
  );
}
