import { FC } from "react";
import { BasicProps } from "../types/BasicProps";
import { IconEthereum } from "./IconEthereum";
import classNames from "classnames";

const RoundedIconEthereum: FC<BasicProps> = ({ className }) => {
  return (
    <div
      className={classNames(
        "aspect-square bg-white rounded-full flex items-center justify-center",
        className
      )}
    >
      <IconEthereum />
    </div>
  );
};

export default RoundedIconEthereum;
