import { OptionsWriteMethod } from "@unlockdfinance/verislabs-web3";
import { wrappedTokenContract } from "../contracts";

class WethModule {
  wrapEth(amount: bigint, options?: OptionsWriteMethod) {
    return wrappedTokenContract.deposit(amount, options);
  }

  unwrapEth(amount: bigint, options?: OptionsWriteMethod) {
    return wrappedTokenContract.withdraw(amount, options);
  }
}

export default new WethModule();
