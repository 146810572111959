"use client";
import env from "env";
import { useEffect } from "react";

export default function SafaryScript() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tag.safary.club/stag-0.1.7.js";
    script.async = true;
    script.setAttribute("data-name", "safary-sdk");
    script.setAttribute("data-product-id", env.safaryProjectId);
    script.integrity = "sha256-9nF4qHptJtYUbNIZf+YPbu9hvMYdtN6ek9AbXMR2mew=";
    script.crossOrigin = "anonymous";

    const target = document.head || document.body;
    target.appendChild(script);
  }, []);

  return null;
}
