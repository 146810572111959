"use client";
import { PropsWithChildren, useState } from "react";
import { useToggle, useUpdateEffect } from "react-use";
import { AlertContext } from "./contexts";
import { AlertNotification } from "../alerts";

export default function AlertProvider({
  children,
}: PropsWithChildren): JSX.Element {
  // alert logic
  const [isAlertNotificationOpen, toggleAlertNotification] = useToggle(false);
  const [typeAlertNotification, setTypeAlertNotification] = useState<
    "error" | "success" | "warning" | "loading" | "info"
  >("error");
  const [contentAlertNotification, setContentAlertNotification] =
    useState<string>("");
  const [timeoutAlertNotification, setTimeoutAlertNotification] =
    useState<number>();

  const openAlertNotification = (
    type: typeof typeAlertNotification,
    content: typeof contentAlertNotification,
    timeout: typeof timeoutAlertNotification
  ) => {
    setTypeAlertNotification(type);
    setContentAlertNotification(content);
    setTimeoutAlertNotification(timeout);
    toggleAlertNotification(true);
  };

  const closeAlertNotification = () => {
    toggleAlertNotification(false);
  };

  useUpdateEffect(() => {
    if (!isAlertNotificationOpen) {
      const timer = setTimeout(() => {
        setTypeAlertNotification("error");
        setContentAlertNotification("");
        setTimeoutAlertNotification(undefined);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isAlertNotificationOpen]);

  return (
    <>
      <AlertContext.Provider
        value={{
          alertNotification: [
            isAlertNotificationOpen,
            openAlertNotification,
            closeAlertNotification,
          ],
        }}
      >
        {children}
      </AlertContext.Provider>
      <AlertNotification
        isOpen={isAlertNotificationOpen}
        toggleAlert={toggleAlertNotification}
        type={typeAlertNotification}
        content={contentAlertNotification}
        timeout={timeoutAlertNotification}
      />
    </>
  );
}
