"use client";
import classNames from "classnames";
import { type FC } from "react";
import { useModalConnectWallets } from "../hooks/useModalConnectWallets";
import { CtaPrimary } from "..";
import { BasicProps } from "../types/BasicProps";

export const ButtonConnectWallet: FC<BasicProps> = (props) => {
  const { className } = props;
  const [, toggleModal] = useModalConnectWallets();

  return (
    <>
      <CtaPrimary
        className={classNames("break-normal min-w-fit py-[5px]", [className])}
        onClick={() => toggleModal()}
      >
        <span className="text-xs">Connect Wallet</span>
      </CtaPrimary>
    </>
  );
};
