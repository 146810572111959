import type { ComponentPropsWithoutRef, FC } from "react";
import classNames from "classnames";

export const Divider: FC<ComponentPropsWithoutRef<"div">> = ({
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames("border-t border-t-white w-full", [className])}
    ></div>
  );
};
