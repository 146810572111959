"use client";
import classNames from "classnames";
import { type FC } from "react";
import { ButtonConnectWallet } from "./ButtonConnectWallet";
import { ButtonPanelWallet } from "./ButtonPanelWallet";
import { useCustomWallet } from "../providers";
import { BasicProps } from "../types/BasicProps";

export const Wallet: FC<BasicProps> = ({ className }) => {
  const { isConnected } = useCustomWallet();

  return (
    <>
      {!isConnected && (
        <ButtonConnectWallet className={classNames("w-[130px]", [className])} />
      )}
      {isConnected && (
        <ButtonPanelWallet className={classNames("w-[130px]", [className])} />
      )}
    </>
  );
};
