"use client";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { type FC } from "react";
import { useToggle } from "react-use";
import { PanelWallet } from "./PanelWallet";
import { IconMetamask, IconWalletConnect } from "../../icons";
import { CtaPrimary } from "../..";
import { parseHashToRender } from "../../../utils";
import { useCustomWallet } from "../../providers";
import { BasicProps } from "../../types/BasicProps";
import Image from "next/image";

export const ButtonPanelWallet: FC<BasicProps> = (props) => {
  const { className } = props;
  const { address, connector } = useCustomWallet();
  const [isOpen, togglePanel] = useToggle(false);
  const connectorName = connector?.name;

  return (
    <>
      <div className={classNames("relative z-50", [className])}>
        <CtaPrimary
          onClick={() => togglePanel()}
          className={classNames(
            "pl-[18px] pr-[10px] py-[5px] break-normal min-w-fit flex items-center justify-center relative z-10",
            [className]
          )}
        >
          <span className="text-xs">
            {address ? parseHashToRender(address) : "..."}
          </span>
          <div className="relative ml-2.5 w-5 h-5 flex items-center justify-center">
            {connector?.icon && (
              <Image
                src={connector.icon}
                alt={connector.name}
                width={24}
                height={24}
              />
            )}
            {connectorName === "WalletConnect" && <IconWalletConnect />}
          </div>
        </CtaPrimary>
        <Transition
          show={isOpen}
          enterFrom="opacity-0"
          enter="transition-opacity duration-300"
          leave="transition-opacity duration-300"
          leaveTo="opacity-0"
          className="relative h-0 w-full"
        >
          <PanelWallet isOpen={isOpen} togglePanel={togglePanel} />
        </Transition>
      </div>
    </>
  );
};
