"use client";
import { type FC, useState } from "react";
import classNames from "classnames";
import { withAlert } from "./withAlert";
import { useTimeoutFn } from "react-use";
import { IoMdClose } from "react-icons/io";
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface Props {
  className?: string;
  isOpen: boolean;
  toggleAlert: (nextValue?: boolean) => void;
  timeout?: number;
  type: "success" | "error" | "warning" | "loading" | "info";
  content: string;
}

type Controller = (props: Props) => Props & {
  pauseCountdown: () => void;
  resumeCountDown: () => void;
};

const useController: Controller = (props) => {
  const { timeout = 5000, toggleAlert, ...restProps } = props;
  const [isReady, cancel, reset] = useTimeoutFn(() => {
    toggleAlert(false);
  }, timeout);

  const pauseCountdown = () => {
    cancel();
  };

  const resumeCountDown = () => {
    reset();
  };

  return { pauseCountdown, resumeCountDown, toggleAlert, ...restProps };
};

export const AlertNotification: FC<Props> = withAlert((props: Props) => {
  const {
    toggleAlert,
    className,
    type,
    content,
    pauseCountdown,
    resumeCountDown,
  } = useController(props);
  return (
    <>
      <div
        onMouseEnter={pauseCountdown}
        onMouseLeave={resumeCountDown}
        className={classNames(`relative w-[calc(100vw-24px-24px)] max-w-xl`, [
          className,
        ])}
      >
        <button
          className={classNames(
            `w-6 h-6 cursor-pointer absolute -right-2 -top-2 p-1 z-10
            border rounded-full bg-secondary text-white`,
            {
              "border-green-400": type === "success",
              "border-amber-500": type === "warning",
              "border-red-700": type === "error",
              "border-blue-500": type === "info",
              "border-blue-600": type === "loading",
            }
          )}
          onClick={() => {
            toggleAlert(false);
          }}
        >
          <IoMdClose className="w-full h-full" />
        </button>
        <div
          className={classNames(
            `w-full h-full overflow-hidden relative xs:px-6 px-2.5 py-2 xs:py-4 rounded-2xl
          border-2 bg-secondary element-shadow`,
            {
              "border-green-400": type === "success",
              "border-amber-500": type === "warning",
              "border-red-700": type === "error",
              "border-blue-500": type === "info",
              "border-blue-600": type === "loading",
            }
          )}
        >
          <div className="xs:text-sm text-xs max-3xs:text-2xs font-medium text-center text-white relative z-10 flex justify-center items-center gap-2">
            {type === "success" && <AiOutlineCheck />}
            {(type === "error" || type === "info" || type === "warning") && (
              <AiOutlineInfoCircle />
            )}
            {type === "loading" && (
              <AiOutlineLoading3Quarters className="animate-spin" />
            )}

            <p>{content}</p>
          </div>
        </div>
      </div>
    </>
  );
});
