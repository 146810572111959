import { FC, ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import { Anchor } from "../Anchor";
import { BasicProps } from "components/types/BasicProps";
import track from "logic/track";

type Props = BasicProps & {
  id: string;
  children: string;
  href: string;
  target: string;
  rel: string;
  title: string;
  isActive?: boolean;
};

export const NavigationElement: FC<Props> = forwardRef(
  function NavigationElement(props, ref: ForwardedRef<HTMLLIElement>) {
    const { id, children, href, target, rel, title, className, isActive } =
      props;
    return (
      <li
        ref={ref}
        className={classNames(
          `cursor-pointer text-sm font-semibold md:text-base transition-all duration-300`,
          [className],
          isActive ? "text-uyellow" : "text-gray-300 hover:text-white"
        )}
        onClick={() => track.click(`navbar_click_${id}`)}
      >
        <Anchor
          className="px-3 py-2 md:px-6 h-full w-full grid place-content-center"
          id={id}
          href={href}
          target={target}
          title={title}
          rel={rel}
        >
          {children}
        </Anchor>
      </li>
    );
  }
);
