"use client";
import { useEffect, useRef, useState } from "react";
import { formatUnits, parseUnits } from "viem";

export type BigIntInputProps = {
  decimals: number;
  value: string;
  onChange: (value: string) => void;
  renderInput?: (
    props: React.HTMLProps<HTMLInputElement>
  ) => React.ReactElement;
  autofocus?: boolean;
  placeholder?: string;
  max?: string;
  min?: string;
  name?: string;
  className?: string;
  readOnly?: boolean;
  isNegativeAllowed?: boolean;
  digits?: number;
};

export function BigIntInput({
  decimals = 18,
  digits = 18,
  value,
  onChange,
  renderInput,
  autofocus,
  readOnly,
  name,
  placeholder = "0.00",
  max,
  min,
  isNegativeAllowed,
  ...extraProps
}: BigIntInputProps) {
  const inputRef = useRef<any>(null);

  const [inputValue, setInputvalue] = useState("");

  // update current value
  useEffect(() => {
    if (!value) {
      setInputvalue("");
    } else {
      let parseInputValue;

      try {
        parseInputValue = parseUnits(inputValue || "0", digits);
      } catch {
        // do nothing
      }

      if (parseInputValue == null || parseInputValue.toString() !== value) {
        setInputvalue(formatUnits(BigInt(value), digits));
      }
    }
  }, [value, digits, inputValue]);

  useEffect(() => {
    if (!renderInput && autofocus && inputRef) {
      const node = inputRef.current as HTMLInputElement;
      node.focus();
    }
  }, [autofocus, inputRef]);

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.currentTarget;

    if (
      (!isNegativeAllowed && value.startsWith("-")) ||
      value.split(".").length > 2
    ) {
      return;
    }

    const dotIndex = value.indexOf(".");

    if (dotIndex > -1) value = value.slice(0, dotIndex + decimals + 1);

    if (value === "") {
      onChange(value);
      setInputvalue(value);
      return;
    }

    let newValue: BigInt;
    try {
      newValue = parseUnits(value, digits);
    } catch (e) {
      // don't update the input on invalid values
      return;
    }

    const invalidValue =
      (min && BigInt(newValue.toString()) < BigInt(min)) ||
      (max && BigInt(newValue.toString()) > BigInt(max));
    if (invalidValue) {
      return;
    }

    setInputvalue(value);
    onChange(newValue.toString());
  };

  const inputProps = {
    placeholder,
    onChange: updateValue,
    type: "text",
    value: inputValue,
  };

  return renderInput ? (
    renderInput({ ...inputProps, ...extraProps })
  ) : (
    <input
      name={name}
      id={name}
      {...inputProps}
      {...extraProps}
      readOnly={readOnly}
      ref={inputRef}
    />
  );
}
