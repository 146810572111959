import { useToggle } from "react-use";
import { useWeb3ModalGuard } from "../shared";

const useModalHelper = (): [
  boolean,
  (newValue?: boolean) => void,
  () => void
] => {
  const [isOpen, toggle] = useToggle(false);
  const launcher = useWeb3ModalGuard(isOpen, toggle);
  return [isOpen, toggle, launcher];
};

export default useModalHelper;
