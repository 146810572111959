import classNames from "classnames";
import { BasicProps } from "components/types/BasicProps";
import { FC } from "react";
import { headerLiterals } from "./literals";
import Link from "next/link";
import track from "logic/track";

const { activeLoansLabel, buyNowLabel, myActivityLabel, txHistoryLabel } =
  headerLiterals.marketplacePanel;

export const MarketplacePanel: FC<BasicProps> = (props) => {
  const { className } = props;

  return (
    <ul
      className={classNames(
        "bg-secondary border-uyellow border-t-2 border-t-secondary border-r border-b border-l rounded-b-[15px] px-[30px] py-[15px] flex flex-col gap-[15px]",
        className
      )}
    >
      <li className="w-max">
        <Link
          className="text-base font-normal"
          href="/marketplace/buy-nfts"
          target="_self"
          legacyBehavior
        >
          <a onClick={() => track.click('mktp_click_buynfts')}>
            {buyNowLabel}
          </a>
        </Link>
      </li>
      <li className="w-max">
        <Link
          className="text-base font-normal"
          href="/marketplace/transaction-history"
          target="_self"
          legacyBehavior
        >
          <a onClick={() => track.click('mktp_click_txnhistory')}>
            {txHistoryLabel}
          </a>
        </Link>
      </li>
      <li className="w-max">
        <Link
          className="text-base font-normal"
          href="/marketplace/active-loans"
          target="_self"
          legacyBehavior
        >
          <a onClick={() => track.click('mktp_click_nearliq')}>
            {activeLoansLabel}
          </a>
        </Link>
      </li>
      <li className="w-max">
        <Link
          className="text-base font-normal"
          href="/marketplace/my-activity"
          target="_self"
          legacyBehavior
        >
          <a onClick={() => track.click('mktp_click_myactivity')}>
            {myActivityLabel}
          </a>
        </Link>
      </li>
    </ul>
  );
};
