"use client";
import { FC } from "react";
import { ModalNotifications } from "../modals";
import { useWeb3ModalGuard } from "../modals/shared";
import { useToggle } from "react-use";
import IconNotification from "../icons/IconNotification";

type Props = {
  className?: string;
};

export const NotificationButton: FC<Props> = (props) => {
  const [isOpen, toggleModal] = useToggle(false);
  const launcherModal = useWeb3ModalGuard(isOpen, toggleModal);

  const handleOnNotificationClick = () => launcherModal();

  return (
    <>
      <button
        className="w-[26px] h-[26px] flex items-center justify-center"
        onClick={handleOnNotificationClick}
      >
        <IconNotification />
      </button>
      {isOpen && (
        <ModalNotifications isOpen={isOpen} toggleModal={toggleModal} />
      )}
    </>
  );
};
