"use client";
import classNames from "classnames";
import {
  SupportedChainIds,
  externalWalletModule,
  verisModule,
} from "clients/verisModule";
import { useWallet } from "components/providers/WalletProvider";
import { useChain } from "components/providers/ChainProvider";
import { BasicProps } from "components/types/BasicProps";
import { handleErrors } from "errors";
import { useCustomParams, useErrorAlert } from "logic/hooks";
import Chain from "logic/types/chain/Chain";
import Image from "next/image";
import { FC, useState } from "react";
import firstLetterToUppercase from "utils/firstLetterToUppercase";

export const NetworkPanel: FC<BasicProps> = (props) => {
  const { className } = props;
  const { chainId, chainIdConnected } = useChain();
  const { isConnected } = useWallet();
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);

  const { setOrReplaceParamValue } = useCustomParams();

  const switchNetwork = async (_chainId: SupportedChainIds) => {
    // if the chain connected is different from the chain selected
    // switch the chain on the wallet
    if (isConnected && chainIdConnected !== _chainId) {
      try {
        await externalWalletModule.switchChain(_chainId);
      } catch (err) {
        setError(handleErrors(err));
      }
      // if the chain connected is the same as the chain selected
      // but the dapp is on a different chain
      // switch the chain on the dapp replacing the param value
    } else if (!isConnected) {
      setOrReplaceParamValue("chain", Chain.get(_chainId).name);
    } else if (chainId !== _chainId) {
      setOrReplaceParamValue("chain", Chain.get(_chainId).name);
    }
    // otherwise do nothing
  };

  return (
    <ul
      className={classNames(
        "w-[159px] border border-uyellow rounded-[15px] bg-dark p-[15px] text-xs font-semibold flex flex-col gap-2.5",
        className
      )}
    >
      {verisModule.networks.map((network) => (
        <li
          key={network.CHAIN.id}
          className="flex items-center gap-[5px]"
          onClick={() => switchNetwork(network.CHAIN.id)}
        >
          <Image
            src={network.images.srcSecondary}
            width={18}
            height={18}
            alt={network.CHAIN.name}
          />
          <span>{firstLetterToUppercase(network.CHAIN.name)}</span>
        </li>
      ))}
    </ul>
  );
};
