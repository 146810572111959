import { FC } from "react";
import { BasicProps } from "../types/BasicProps";
import classNames from "classnames";
import Image from "next/image";
import { ICurrency } from "logic/types/currency/ICurrency";

type Props = BasicProps & {
  currency: ICurrency;
  secondary?: boolean;
};

const TokenIcon: FC<Props> = ({ className, currency, secondary = false }) => {
  return (
    <div className={classNames("relative", className)}>
      <Image
        className="object-cover h-full"
        src={secondary ? currency.srcSecondary : currency.srcPrimary}
        alt={currency.name}
        fill
      />
    </div>
  );
};

export default TokenIcon;
