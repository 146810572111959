export default function IconNotification() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
    >
      <rect
        x="0.210938"
        y="0.414062"
        width="26"
        height="26"
        rx="13"
        fill="#5E36FF"
      />
      <path
        d="M20.5849 16.3941C19.3854 15.4889 18.6976 14.1657 18.6976 12.7639V10.7891C18.6976 8.29644 16.6238 6.23306 13.9376 5.8874V5.1224C13.9376 4.73069 13.5822 4.41406 13.1443 4.41406C12.7064 4.41406 12.3509 4.73069 12.3509 5.1224V5.8874C9.66392 6.23306 7.59094 8.29644 7.59094 10.7891V12.7639C7.59094 14.1657 6.90312 15.4889 5.69646 16.3998C5.38785 16.6356 5.21094 16.9785 5.21094 17.3411C5.21094 18.0247 5.8337 18.5807 6.59927 18.5807H19.6893C20.4548 18.5807 21.0776 18.0247 21.0776 17.3411C21.0776 16.9785 20.9007 16.6356 20.5849 16.3941Z"
        fill="white"
      />
      <path
        d="M13.1443 21.4141C14.581 21.4141 15.7829 20.4996 16.059 19.2891H10.2296C10.5056 20.4996 11.7075 21.4141 13.1443 21.4141Z"
        fill="white"
      />
    </svg>
  );
}
