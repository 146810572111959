"use client";
import { FC, FormEvent, useEffect, useMemo, useState } from "react";
import Input from "../shared/Input";
import { GenericModal } from "../GenericModal";
import { useErrorAlert } from "../../../logic/hooks";
import { handleErrors } from "../../../errors";
import { ModalProps } from "../../types/ModalProps";
import DataField from "../../DataField";
import { FormTitleRadio } from "./FormTitleRadio";
import CtaSecondary from "../../CtaSecondary";
import CtaPrimary from "../../CtaPrimary";
import wethModule from "../../../logic/WethModule";
import useAlertNotification from "../../hooks/useAlertNotification";
import { approveInWalletLiteral } from "../../../literals";
import { useTokenBalance } from "../../providers/BalanceProvider";
import { Erc20Currency, NativeCurrency } from "logic/types/currency/Currency";
import currenciesModule from "logic/CurrenciesModule";
import { useChain } from "components/providers/ChainProvider";

enum Options {
  WRAP = "WRAP",
  UNWRAP = "UNWRAP",
}

const ModalWeth: FC<ModalProps> = (props) => {
  const { isOpen, toggleModal } = props;
  const [optionSelected, setOptionSelected] = useState<Options>(Options.WRAP);
  const [error, setError] = useState<Error | null>(null);
  const { chainId } = useChain();
  const [nativeCurrency, setNativeCurrency] = useState<NativeCurrency>(
    currenciesModule.getNativeCurrency(chainId)
  );
  const [wrappedCurrency, setWrappedCurrency] = useState<
    Erc20Currency | undefined
  >(currenciesModule.getWrappedNativeCurrency(chainId));
  useErrorAlert(error);
  const currency = currenciesModule.getNativeCurrency(chainId);
  const { balance: nativeBalance, forceBalanceUpdate } = useTokenBalance(
    nativeCurrency.id
  );
  const { balance: wrappedBalance } = useTokenBalance(wrappedCurrency?.id);
  const [amount, setAmount] = useState<bigint>(BigInt(0));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [, openAlertNotification] = useAlertNotification();

  const handleOptionChange = (option: Options) => {
    setOptionSelected(option);
  };

  const handleAmountChange = (_amount: bigint) => {
    setAmount(_amount);
  };

  const max = useMemo<bigint | undefined>(
    () => (optionSelected === Options.WRAP ? nativeBalance : wrappedBalance),
    [nativeBalance, wrappedBalance, optionSelected]
  );

  useEffect(() => {
    setNativeCurrency(currenciesModule.getNativeCurrency(chainId));
    setWrappedCurrency(currenciesModule.getWrappedNativeCurrency(chainId));
  }, [chainId]);

  const handleWrapEth = async () => {
    try {
      await wethModule.wrapEth(amount, {
        onSignaturePending: () => {
          setLoading(false);
          openAlertNotification("loading", approveInWalletLiteral, 50000);
        },
      });

      openAlertNotification("success", "Eth wrapped successfully", 5000);
    } catch (err) {
      setError(handleErrors(err));
    } finally {
      setLoading(false);
      forceBalanceUpdate();
    }
  };

  const handleUnwrapEth = async () => {
    try {
      await wethModule.unwrapEth(amount, {
        onSignaturePending: () => {
          setLoading(false);
          openAlertNotification("loading", approveInWalletLiteral, 50000);
        },
      });

      openAlertNotification("success", "Eth unwrapped successfully", 5000);
    } catch (err) {
      setError(handleErrors(err));
    } finally {
      setLoading(false);
      forceBalanceUpdate();
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (!isLoading && amount > BigInt(0)) {
      if (optionSelected === Options.WRAP) {
        handleWrapEth();
      } else {
        handleUnwrapEth();
      }
    }
  };

  const actions = useMemo(
    () => (
      <>
        <CtaSecondary
          className="w-40"
          type="button"
          onClick={() => toggleModal(false)}
        >
          Cancel
        </CtaSecondary>
        <CtaPrimary className="w-40" type="submit">
          {optionSelected === Options.WRAP ? "Wrap" : "Unwrap"}
        </CtaPrimary>
      </>
    ),
    [optionSelected]
  );

  return (
    <GenericModal
      isOpen={isOpen}
      toggleModal={toggleModal}
      onSubmit={handleSubmit}
      headerTitle="Eth wrapper"
      actions={actions}
    >
      <div className="mt-4 flex justify-center gap-10">
        <DataField
          item={{
            type: "currency",
            currency,
            label: "Eth balance",
            value: nativeBalance,
          }}
        />
        <DataField
          item={{
            type: "currency",
            currency,
            label: "Weth balance",
            value: wrappedBalance,
          }}
        />
      </div>
      <div className="w-full flex justify-center gap-1 sm:gap-2">
        <FormTitleRadio
          className="w-1/2"
          checked={optionSelected === Options.WRAP}
          id="Wrap"
          name={Options.WRAP}
          onChange={handleOptionChange}
          value={Options.WRAP}
          title="Wrap"
        />
        <FormTitleRadio
          className="w-1/2"
          checked={optionSelected === Options.UNWRAP}
          id="Unwrap"
          name={Options.UNWRAP}
          onChange={handleOptionChange}
          value={Options.UNWRAP}
          title="Unwrap"
        />
      </div>
      <Input
        className="my-4"
        nameInput="Amount"
        onChange={handleAmountChange}
        value={amount}
        max={max}
      />
    </GenericModal>
  );
};

export default ModalWeth;
