"use client";
import { Disclosure } from "@headlessui/react";
import { NavigationElement } from "./NavigationElement";
import classNames from "classnames";
import Image from "next/image";
import { useState, type FC, useRef } from "react";
import { Anchor } from "../Anchor";
import { usePathname } from "next/navigation";
import { Wallet } from "../wallet";
import { NotificationButton } from "./NotificationButton";
import IconDiscord from "../icons/IconDiscord";
import IconX from "../icons/IconX";
import Link from "next/link";
import { BasicProps } from "../types/BasicProps";
import { DocsIcon, EthIconYellow } from "components/icons";
import { MarketplacePanel } from "./MarketplacePanel";
import { FaChevronUp } from "react-icons/fa";
import { useFaucetEnabled, useOutsideAlerter } from "logic/hooks";
import track from "logic/track";
import { IoMdArrowDropdown } from "react-icons/io";
import { NetworkPanel } from "./NetworkPanel";
import { verisModule } from "clients/verisModule";
import { useChain } from "components/providers/ChainProvider";

export const Header: FC<BasicProps> = (props) => {
  const pathname = usePathname();
  const { className } = props;
  const [isMarketplacePanelOpen, setIsMarketplacePanelOpen] =
    useState<boolean>(false);
  const [isNetworkPanelOpen, setNetworkPanelOpen] = useState<boolean>(false);
  const isFaucetEnabled = useFaucetEnabled();
  const { chainId, isChainBlocked } = useChain();

  const wrapperMarketplaceRef = useRef<any>(null);
  const wrapperNetworkRef = useRef<any>(null);

  useOutsideAlerter(wrapperMarketplaceRef, () =>
    setIsMarketplacePanelOpen(false)
  );
  useOutsideAlerter(wrapperNetworkRef, () => setNetworkPanelOpen(false));

  const handleMarketplaceClick = () => {
    const status = !isMarketplacePanelOpen;

    status && track.click("navbar_click_marketplace");

    setIsMarketplacePanelOpen(status);
  };

  const handleNetworkClick = () => {
    const status = !isNetworkPanelOpen;

    setNetworkPanelOpen(status);
  };

  return (
    <>
      <Disclosure
        as="header"
        className={classNames(`sticky w-full top-0 h-[95px] z-[999999999]`, [
          className,
        ])}
      >
        <div className="flex w-full h-full bg-secondary items-center justify-between">
          <nav className="flex flex-row h-full">
            <Anchor
              href="/dashboard"
              className="-mt-2 px-3 py-2 md:px-6 h-full grid place-content-center"
              title="Unlockd"
              rel="bookmark"
              target="_self"
            >
              <div className="relative w-36 translate-y-0.5">
                <Image
                  width={1400}
                  height={300}
                  alt="Unlockd"
                  src="/assets/images/logo-unlockd.png"
                />
              </div>
            </Anchor>
            <ul className="z-50 flex items-center">
              <NavigationElement
                id="dashboard"
                href="/dashboard"
                target="_self"
                title="dashboard"
                rel="bookmark"
                isActive={pathname.includes("dashboard")}
              >
                Dashboard
              </NavigationElement>
              <NavigationElement
                id="account"
                href="/account"
                target="_self"
                title="account"
                rel="bookmark"
                isActive={pathname.includes("account")}
              >
                Account
              </NavigationElement>
              <NavigationElement
                id="earn"
                href="/earn"
                target="_self"
                title="earn"
                rel="bookmark"
                isActive={pathname.includes("earn")}
              >
                Earn
              </NavigationElement>
              <NavigationElement
                id="borrow"
                href="/borrow"
                target="_self"
                title="borrow"
                rel="bookmark"
                isActive={pathname.includes("borrow")}
              >
                Borrow
              </NavigationElement>
              <button
                className="relative px-3 py-2 flex gap-1 items-center"
                onClick={handleMarketplaceClick}
                type="button"
                ref={wrapperMarketplaceRef}
              >
                <span
                  className={classNames(
                    `cursor-pointer text-sm font-semibold md:text-base transition-all duration-300`,
                    pathname.includes("marketplace")
                      ? "text-uyellow"
                      : "text-gray-300 hover:text-white"
                  )}
                >
                  Marketplace
                </span>
                <div className="mt-0.5 w-[10px] h-[5px] flex items-center justify-center">
                  <FaChevronUp
                    className={classNames(
                      "transition-transform duration-200 transform",
                      isMarketplacePanelOpen ? "rotate-0" : "rotate-180",
                      pathname.includes("marketplace") && "text-uyellow"
                    )}
                    width={15}
                    height={10}
                  />
                </div>
                {isMarketplacePanelOpen && (
                  <MarketplacePanel className="absolute top-[67px] -left-[15px]" />
                )}
              </button>
              {isFaucetEnabled && (
                <NavigationElement
                  id="faucet"
                  href="/faucet"
                  target="_self"
                  title="faucet"
                  rel="bookmark"
                  isActive={pathname.includes("faucet")}
                >
                  Faucet
                </NavigationElement>
              )}
            </ul>
          </nav>
          <div className="h-full flex items-center">
            <Link
              className="flex items-center mr-5"
              target="_blank"
              href="https://docs.unlockd.finance"
              onClick={() => track.click("navbar_click_docs")}
            >
              <DocsIcon />
            </Link>
            <Link
              className="flex items-center mr-5"
              target="_blank"
              href="https://twitter.com/Unlockd_Finance"
            >
              <IconX />
            </Link>
            <Link
              href="https://discord.gg/unlockdofficial"
              className="flex items-center mr-4"
              target="_blank"
            >
              <IconDiscord />
            </Link>
            {!isChainBlocked && (
              <div className="flex items-center mr-4">
                <button
                  className="relative h-[27px] border border-uyellow rounded-[14px] py-[5px] px-[10px] flex items-center gap-2"
                  onClick={handleNetworkClick}
                  ref={wrapperNetworkRef}
                >
                  <Image
                    src={verisModule.getNetwork(chainId).images.srcPrimary}
                    alt={verisModule.getNetwork(chainId).CHAIN.name}
                    width={14}
                    height={18}
                  />
                  <IoMdArrowDropdown className="text-uyellow" />
                  {isNetworkPanelOpen && (
                    <NetworkPanel className="absolute top-[36px] right-0" />
                  )}
                </button>
              </div>
            )}
            <div className="flex items-center mr-2.5">
              <NotificationButton />
            </div>
            <Wallet className="mr-6" />
          </div>
        </div>
        <div className="h-[2px] bg-gradient-to-r from-transparent via-uyellow to-transparent"></div>
      </Disclosure>
    </>
  );
};
