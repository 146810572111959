import { type FC, useMemo } from "react";
import classNames from "classnames";
import { BasicProps } from "../../types/BasicProps";

type Props = BasicProps & {
  title: string;
  name: string;
  value: string;
  id: string;
  checked: boolean;
  onChange: (value: string) => void;
};

export const FormTitleRadio: FC<Props> = ({
  className,
  title,
  onChange,
  id,
  checked,
  ...restProps
}) => {
  const styles = useMemo<string>(
    () =>
      checked
        ? "font-semibold text-primary border-primary"
        : "text-white border-white",
    [checked]
  );

  return (
    <label
      className={classNames(
        "border-b pt-3 sm:pt-6 pb-2 text-base sm:text-2xl text-center cursor-pointer",
        className,
        styles
      )}
      htmlFor={id}
    >
      <input
        className="hidden"
        type="radio"
        id={id}
        checked={checked}
        onChange={() => onChange(restProps.value)}
        {...restProps}
      />
      {title}
    </label>
  );
};
