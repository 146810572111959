"use client";
import { useState, useEffect, type PropsWithChildren, type FC } from "react";

export const ClientSideOnly: FC<PropsWithChildren> = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  return <div>{children}</div>;
};
