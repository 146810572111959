import { MarketItemType } from "../../../contracts/MarketContract";
import { Address } from "viem";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import currenciesModule from "logic/CurrenciesModule";
import { INft } from "../nft/INft";
import { IErc20Currency } from "../currency/ICurrency";
import { Hash } from "@unlockdfinance/verislabs-web3";
import { parseEndTime } from "logic/helpers/parseEntTime";
import { TheGraph } from "data/TheGraphService";

export enum HistoryItemType {
  PURCHASE = "Purchase",
  BID = "Bid",
}

export default class TransactionHistoryItem {
  readonly type: MarketItemType;
  readonly nft: INft;
  readonly id: Address;
  readonly status: TheGraph.MarketItemStatus;
  readonly price: bigint | null;
  readonly buyer: Address | null;
  private readonly subgraphItem: TheGraph.MarketItem;
  readonly date: Date | null;
  readonly historyType: HistoryItemType | null;
  readonly currency: IErc20Currency;
  readonly txHash: Hash;

  constructor(subgraphItem: TheGraph.MarketItem, nft: INft) {
    this.subgraphItem = subgraphItem;
    this.type = this.subgraphItem.orderType as MarketItemType;
    this.nft = nft;
    this.id = this.subgraphItem.id as `0x${string}`;
    this.date = subgraphItem.date
      ? new Date(parseEndTime(subgraphItem.date))
      : null;
    this.status = this.subgraphItem.status;
    this.currency = currenciesModule
      .getErc20Currencies(nft.chainId)
      .find(({ address }) =>
        equalIgnoreCase(this.subgraphItem.loan.underlyingAsset, address)
      )!;

    if (this.subgraphItem.status === TheGraph.MarketItemStatus.CLAIMED) {
      this.price = BigInt(this.subgraphItem.bidAmount);
      this.buyer = this.subgraphItem.bidder;
      this.historyType = HistoryItemType.BID;
    } else if (this.subgraphItem.status === TheGraph.MarketItemStatus.BOUGHT) {
      this.price = BigInt(this.subgraphItem.buyerAmount);
      this.buyer = this.subgraphItem.buyer;
      this.historyType = HistoryItemType.PURCHASE;
    } else {
      throw new Error("Order status not supported");
    }

    this.txHash = this.subgraphItem.transactionHash;
  }
}
