export default function IconX() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      viewBox="0 0 22 20"
      fill="none"
    >
      <path
        d="M0.0523084 0L8.33567 11.0312L0 20H1.87602L9.17389 12.1477L15.0704 20H21.4545L12.7051 8.34833L20.4639 0H18.5879L11.8669 7.23185L6.43649 0H0.0523084ZM2.81114 1.37634H5.74405L18.6953 18.6235H15.7624L2.81114 1.37634Z"
        fill="white"
      />
    </svg>
  );
}
